
import { Component, Mixins } from 'vue-property-decorator';
import { mdiCheck, mdiCalendarMultiple } from '@mdi/js';
import Page from './Page.vue';
import DatePreview from '../components/ui/DatePreview.vue';
import BestAthletesLogo from '../components/ui/BestAthletesLogo.vue';
import EventbriteWidget from '../components/events/EventbriteWidget.vue';
import { PollingMixin, VuetifyMixin, FeatureFlagMixin } from '../mixins';
import { formatDatePrettyLong, getTime } from '../helpers';
import AthleteSoccerBackground from '../components/svg/AthleteSoccerBackground.vue';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog.vue';
import CopyToClippboard from '@/components/ui/CopyToClipboard.vue';
import EventDetailsProvider from '@/components/hoc/EventDetailsProvider.vue';
import ViewMapLink from '@/components/ui/ViewMapLink.vue';
import { BestAthletesEventModel } from '@/models/bestAthletesEvent';

@Component({
	metaInfo:{
		title: "Register for assessment",
	},
	components:{
		AthleteSoccerBackground,
		BestAthletesLogo,
		ConfirmationDialog,
		CopyToClippboard,
		DatePreview,
		EventbriteWidget,
		EventDetailsProvider,
		Page,
		ViewMapLink,
	}
})
export default class RegisterForAssessment extends Mixins(PollingMixin, FeatureFlagMixin, VuetifyMixin){
	mdiCheck = mdiCheck;
	mdiCalendarMultiple = mdiCalendarMultiple;
	formatDatePrettyLong = formatDatePrettyLong;
	getTime = getTime;

	get EventbriteEventId(): string{
		return this.feature('DIS-140-assessment-event-id');
	}

	getDateFormatted(event: BestAthletesEventModel): string{
		if(event.start){
			return formatDatePrettyLong(event.start, false, false);
		}
		return '';
	}
	getTimeFormatted(event: BestAthletesEventModel): string{
		if(event.start){
			return getTime(event.start);
		}
		return '';
	}

	getMapLink(event: BestAthletesEventModel): string | null{
		if(event.venue === null) return null;
		if (event.venue.address){
			const address = event.venue.address.localized_address_display;
			return encodeURI('https://www.google.com/maps/search/?api=1&query='+address)
		} else if (event.venue.name){
			const venue = event.venue.name
			return encodeURI('https://www.google.com/maps/search/?api=1&query='+venue)
		}
		return 'https://www.google.com/maps'
	}

}
