
import { Component, Prop, Vue } from 'vue-property-decorator';
import { userStore } from '@/store';
import { AthleteProfileModel } from '@/models';
@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class MyAthleteProfilesProvider extends Vue{
	@Prop({ default: 'div' }) as: string;

	get SlotProps(): Record<string, any>{
		return {
			AthleteProfilesReady: this.AthleteProfilesReady,
			MyAthleteProfiles: this.MyAthleteProfiles,
			AthleteProfile: this.AthleteProfile,
			AthleteById: this.AthleteById,
		};
	}

	/**
	 * Dictionary of athleteId => AthleteProfileModel for easy lookups
	 */
	get AthleteById(): Record<string, AthleteProfileModel>{
		return userStore.MyAthleteProfiles.reduce((dict, athlete) => {
			dict[athlete.id] = athlete;
			return dict;
		}, {});
	}

	get AthleteProfilesReady(): boolean{
		return userStore.athleteProfilesInitialized && !userStore.addAthleteProfileLoading;
	}
	get MyAthleteProfiles(): AthleteProfileModel[]{
		return userStore.MyAthleteProfiles;
	}
	get AthleteProfile(): AthleteProfileModel{
		return userStore.AthleteProfile;
	}

}
