<template>
	<v-img v-bind="$attrs" contain :src="imgAthleteSoccerBackground">
		<slot></slot>
	</v-img>
</template>
<script>
import imgAthleteSoccerBackground from '../../assets/img/athlete-soccer-background.svg'
export default{
	data(){
		return { imgAthleteSoccerBackground }
	}
}
</script>