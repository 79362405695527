
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { mdiCheck } from '@mdi/js';
import Page from './Page.vue';
import DatePreview from '../components/ui/DatePreview.vue';
import BestAthletesLogo from '../components/ui/BestAthletesLogo.vue';
import EventbriteWidget from '../components/events/EventbriteWidget.vue';
import SmartwaiverWidget from '../components/events/SmartwaiverWidget.vue';
import { PollingMixin, VuetifyMixin } from '../mixins';
import { BAEventAttendeeModel, BestAthletesEventModel } from '../models/bestAthletesEvent';
import { formatDatePrettyLong, getTime } from '../helpers';
import AthleteSoccerBackground from '../components/svg/AthleteSoccerBackground.vue';
import { eventDetailsStore } from '../store';
import { BestAthletesEventDetails, EventRegistrationPayload, AvailableFeatures } from '../../types/interfaces';
import { VERIFIED_ASSESSMENT } from '../../types/constants/product-keys';
import { BAEventOrderModel } from '../models/bestAthletesEvent/BAEventOrderModel';
import SubscriptionInfoProvider from '@/components/hoc/SubscriptionInfoProvider.vue';
import { SubscriptionModel } from '@/models/stripe/SubscriptionModel';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog.vue';
import MyAthleteProfilesProvider from '@/components/hoc/MyAthleteProfilesProvider.vue';
import CheckboxCard from '@/components/forms/CheckboxCard.vue';
import CopyToClippboard from '@/components/ui/CopyToClipboard.vue';

@Component({
	components:{
		AthleteSoccerBackground,
		ConfirmationDialog,
		CheckboxCard,
		CopyToClippboard,
		BestAthletesLogo,
		DatePreview,
		EventbriteWidget,
		SmartwaiverWidget,
		SubscriptionInfoProvider,
		Page,
		MyAthleteProfilesProvider,
	},
	beforeRouteUpdate(to,_,next): void{
		if(to.params.eventId){
			eventDetailsStore.loadEvent({ eventId: to.params.eventId });
		}
		next();
	},
})
export default class EventDetails extends Mixins(PollingMixin, VuetifyMixin){

	revealCodes: Record<string, boolean> = {};
	revealedCodes: Record<string, string> = {};
	revealCodesLoading: Record<string, boolean> = {};
	async revealCode(athleteId: string, useFeature: (product: string) => Promise<{code: string}>): Promise<void>{
		Vue.set(this.revealCodesLoading, athleteId, true);
		const { code } = await useFeature(VERIFIED_ASSESSMENT);
		Vue.set(this.revealedCodes, athleteId, code);
		Vue.set(this.revealCodes, athleteId, true);
		Vue.set(this.revealCodesLoading, athleteId, false);
	}

	/** After they click sign waiver, poll the event until they leave the page to get the lastest waiver status when it comes in */
	signWaiverClicked(): void{
		this.pollIntervalMs = 15000;
		this.pollCallback(() => eventDetailsStore.loadEvent({ eventId: this.Event.id }));
	}

	mdiCheck = mdiCheck;
	formatDatePrettyLong = formatDatePrettyLong;
	getTime = getTime;
	@Prop({ default: 450 }) logoHeight: number;
	get Loading(): boolean{
		return !this.EventReady || this.Event === null;
	}
	get EventInitialized(): boolean{
		return eventDetailsStore.initialized;
	}
	get EventReady(): boolean{
		return eventDetailsStore.EventReady;
	}
	get EventId(): string{
		return this.Event.eventbriteId;
	}
	get Event(): BestAthletesEventModel{
		if(eventDetailsStore.eventDetails.event === null) return new BestAthletesEventModel();
		return eventDetailsStore.eventDetails.event;
	}
	get EventDetails(): BestAthletesEventDetails<BestAthletesEventModel, BAEventAttendeeModel, BAEventOrderModel>{
		return eventDetailsStore.eventDetails;
	}

	async orderCompleted(payload: EventRegistrationPayload):  Promise<void>{
		console.log("EventDetails orderCompleted", payload);
		await eventDetailsStore.handleEventRegistration(payload);
	}

	get PriceRange(): string | null{
		if(this.Event === null) return null;
		if(this.AllTicketsFree){
			return "Free Event";
		}
		if(this.Event.PopulatedTicketClassses.length === 1){
			return `${this.MinPrice}`;
		}
		return `${this.MinPrice} - ${this.MaxPrice}`;
	}
	get AllTicketsFree(): boolean{
		return this.Event.PopulatedTicketClassses.map(tc => tc.free).reduce((a,b) => a && b, true);
	}
	get MinPrice(): string | null{
		const [minPrice] = this.Event.PopulatedTicketClassses
			.sort((a,b) => a.TicketCost - b.TicketCost);
		if(minPrice === undefined) return null;
		return minPrice.TicketCostDisplay;
	}
	get MaxPrice(): string | null{
		const [maxPrice] = this.Event.PopulatedTicketClassses
			.sort((a,b) => b.TicketCost - a.TicketCost);
		if(maxPrice === undefined) return null;
		return maxPrice.TicketCostDisplay;
	}

	get EventLocation(): string{
		if(this.Event.onlineEvent){
			return "Online Event";
		}
		if(!this.Event.venue){
			return "To be announced";
		}
		return `${this.Event.venue.name}
		${this.Event.venue.address.address_1}
		${this.Event.venue.address.city} ${this.Event.venue.address.postal_code}
		`;
	}

	get EventLogoFullSize(): string | null{
		if(this.Event === null) return null;
		return this.Event.originalLogo;
	}
	get EventLogo(): string | null{
		if(this.Event === null) return null;
		return this.Event.logo;
	}

	get EventStart(): Date | null{
		if(this.Event === null) return null;
		return this.Event.start;
	}
	get EventName(): string | null{
		if(this.Event === null) return null;
		return this.Event.name;
	}
	get EventDescriptionHtml(): string | null{
		if(this.Event === null) return null;
		return this.Event.descriptionHtml;
	}

	get Order(): BAEventOrderModel | null{
		return this.EventDetails.order;
	}
	get OrderId(): string | null{
		if(this.Order === null) return null;
		return this.Order.id
	}

	get IsRegistered(): boolean{
		return this.Order !== null;
	}
	get HasWaivers(): boolean{
		if(!this.EventReady) return false;
		return this.Event.waivers.waiverTemplates.length > 0;
	}
	get Tickets(): {name: string, number: string, attendee: BAEventAttendeeModel }[]{
		return this.EventDetails.attendees.map(a => ({
			name: a.eventbriteTicketClassName,
			number: a.eventbriteTicketClassId,
			attendee: a,
		}));
	}

	get SignWaiversLoading(): boolean{
		return eventDetailsStore.signWaiversLoading;
	}

	getAvailableAssessments(features: AvailableFeatures): number{
		return SubscriptionModel.getAvailableProductCount(features, VERIFIED_ASSESSMENT);
	}
}
