
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { bestAthletesEventApi } from '@/api/BestAthletesEventApi';
import { BestAthletesEventModel } from '@/models/bestAthletesEvent';
import { PageState } from '@/models/PageState';
type EventDetailsProviderSlotProps = {
	state: PageState;
	event: BestAthletesEventModel;
	EventLocation: string;
	EventLogoFullSize: string | null;
	EventLogo: string | null;
	EventStart: Date | null;
	EventName: string | null;
	EventDescriptionHtml: string | null;
	PriceRange: string | null;
	AllTicketsFree: boolean;
};
@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class EventDetailsProvider extends Vue{
	@Prop({ default: 'div' }) as: string;
	@Prop({ required: true, type: String }) eventbriteEventId: string | null;

	state: PageState = new PageState("Initial");
	event: BestAthletesEventModel | null = null;
	@Watch('eventbriteEventId', { immediate: true })
	async loadEventDetails(): Promise<void>{
		this.state = new PageState("Loading");
		try{
			this.event = await bestAthletesEventApi.getEventbriteEventDetails(this.eventbriteEventId);
			this.state = new PageState("Ready");
		}catch(e){
			this.state = PageState.getPageState(e);
		}
	}


	get SlotProps(): EventDetailsProviderSlotProps{
		return {
			state: this.state,
			event: this.event,
			EventLocation: this.EventLocation,
			EventLogoFullSize: this.EventLogoFullSize,
			EventLogo: this.EventLogo,
			EventStart: this.EventStart,
			EventName: this.EventName,
			EventDescriptionHtml: this.EventDescriptionHtml,
			PriceRange: this.PriceRange,
			AllTicketsFree: this.AllTicketsFree,
		};
	}
	get Event(): BestAthletesEventModel{
		if(this.event === null) return new BestAthletesEventModel();
		return this.event;
	}
	get EventLocation(): string{
		if(this.Event.onlineEvent){
			return "Online Event";
		}
		if(!this.Event.venue){
			return "To be announced";
		}
		return `${this.Event.venue.name}
		${this.Event.venue.address.address_1}
		${this.Event.venue.address.city} ${this.Event.venue.address.postal_code}
		`;
	}
	get PriceRange(): string | null{
		if(this.Event === null) return null;
		if(this.AllTicketsFree){
			return "Free Event";
		}
		if(this.Event.PopulatedTicketClassses.length === 1){
			return `${this.MinPrice}`;
		}
		return `${this.MinPrice} - ${this.MaxPrice}`;
	}
	get MinPrice(): string | null{
		const [minPrice] = this.Event.PopulatedTicketClassses
			.sort((a,b) => a.TicketCost - b.TicketCost);
		if(minPrice === undefined) return null;
		return minPrice.TicketCostDisplay;
	}
	get MaxPrice(): string | null{
		const [maxPrice] = this.Event.PopulatedTicketClassses
			.sort((a,b) => b.TicketCost - a.TicketCost);
		if(maxPrice === undefined) return null;
		return maxPrice.TicketCostDisplay;
	}
	get AllTicketsFree(): boolean{
		return false;
	}
	get EventLogoFullSize(): string | null{
		if(this.Event === null) return null;
		return this.Event.originalLogo;
	}
	get EventLogo(): string | null{
		if(this.Event === null) return null;
		return this.Event.logo;
	}

	get EventStart(): Date | null{
		if(this.Event === null) return null;
		return this.Event.start;
	}
	get EventName(): string | null{
		if(this.Event === null) return null;
		return this.Event.name;
	}
	get EventDescriptionHtml(): string | null{
		if(this.Event === null) return null;
		return this.Event.descriptionHtml;
	}

}
