
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';
@Component
export default class CheckboxCard extends Mixins(VuetifyMixin){

	@Prop({ default: 'baColorVibrantBlue' }) color: string
	@Prop({ type: Boolean, default: false }) value: boolean
	updateValue(val: boolean): void{
		this.$emit('input', val);
	}

	get CardStyle(): Record<string, any>{
		if(this.value === true){
			return {
				'border': `2px solid ${this.getColor(this.color)} !important`,
			}
		}
		return {
			'border': `1px solid ${this.getColor('baColorGray1',)} !important`,
		};
	}

}
