
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getShortMonthName, getDate } from '../../helpers';
@Component
export default class DatePreview extends Vue{
	getShortMonthName = getShortMonthName;
	getDate = getDate;
	@Prop({ required: true }) date: Date;
	@Prop({ default: 12 }) size: number;
	@Prop({ default: 'baColorSecondaryText' }) color: string;

	get ColorClass(): string{
		return `${this.color}--text`;
	}

	get MonthStyle(): Record<string,string>{
		return {'font-size': `${this.size}px`, 'line-height': '10px'};
	}
	get DateStyle(): Record<string,string>{
		return {'font-size': `${this.size * (16/12)}px`};
	}
}
