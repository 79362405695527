
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import viewMapPic from "@/assets/img/view-map-icon.png";
import Vue from 'vue'
export default Vue.extend({
	props:{
		mapLink:{
			type: String,
			required: true
		}
	},
	data(){
		return {
			viewMapPic,
		}
	}
})
