
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mdiAlertRhombus, mdiCheck } from '@mdi/js';
import { BestAthletesEventDetails } from '../../../types/interfaces';
import { BestAthletesEventModel } from '../../models/bestAthletesEvent';
import { BAEventOrderModel } from '../../models/bestAthletesEvent/BAEventOrderModel';
import { SWTemplate } from '../../../types/interfaces/SmartwaiverTypes';
import { bestAthletesEventApi } from '../../api/BestAthletesEventApi';
@Component
export default class SmartwaiverWidget extends Vue{
	mdiAlertRhombus = mdiAlertRhombus;
	mdiCheck = mdiCheck;
	@Prop({ required: true }) eventDetails: BestAthletesEventDetails<BestAthletesEventModel, any, BAEventOrderModel>;

	get EventbriteEventId(): string{
		return this.eventDetails.event.eventbriteId;
	}

	@Prop({ default: false }) loading: boolean;
	/**
	 * Smartwaiver's AutoTag feature lets us add a custom field to the waiver. In this case we use the order id. We can get it from the `autoTag` field when the webhook is triggered.
	 */
	signWaiverWithAutoTag(template: SWTemplate): void{
		console.log("signWaiverWithAutoTag", { template });
		const url = `https://waiver.smartwaiver.com/auto/?auto_waiverid=${template.templateId}&auto_tag=${this.Order.orderIdBase64}`;
		window.open(url, '_blank');
		this.$emit('sign');
	}
	loadingWaiverPDF: boolean[] = [];
	async viewWaiver(templateId: string, index: number): Promise<void>{
		if(this.loadingWaiverPDF[index] === true){
			console.warn("Download already in progress");
			return;
		}
		const waiver = this.Order.PopulatedWaivers.find(w => w.smartwaiverTemplateId === templateId);
		Vue.set(this.loadingWaiverPDF,index, true);
		try{
			const blob = await bestAthletesEventApi.getEventWaiverPdf({ eventbriteEventId: this.EventbriteEventId, waiverId: waiver.smartwaiverId });
			let dl = document.createElement('a');
			dl.href = window.URL.createObjectURL(blob);
			dl.download = `${waiver.title}.pdf`;
			dl.click();
			document.removeChild(dl);
		}catch(e){
			console.error("Failed to download waiver", e);
		}finally{
			Vue.set(this.loadingWaiverPDF,index, false)
		}
	}

	get Order(): BAEventOrderModel{
		return this.eventDetails.order;
	}

	get AllWaiversSigned(): boolean{
		return this.TotalWaiverCount > 0 && (this.SignedWaiverCount === this.TotalWaiverCount);
	}
	get SignedWaiverCount(): number{
		return this.WaiverTemplates.filter(w => w.isSigned === true).length;
	}
	get TotalWaiverCount(): number{
		return this.WaiverTemplates.length;
	}

	get WaiverTemplates(): { isSigned: boolean, template: SWTemplate }[]{
		return this.eventDetails.event.waivers.waiverTemplates.map(t => ({
			isSigned: this.Order.waiverIsSigned(t.templateId),
			template: t,
		}));
	}
}
